import React from "react";

export type ButtonModes =
  | "default"
  | "primary"
  | "success"
  | "danger"
  | "warning"
  | "info"
  | "secondary";

export interface BaseButtonProps {
  mode?: ButtonModes;
  small?: boolean;
  large?: boolean;
  block?: boolean;
  plain?: boolean;
}

export interface ButtonProps
  extends BaseButtonProps,
    Omit<
      React.DetailedHTMLProps<
        React.ButtonHTMLAttributes<HTMLButtonElement>,
        HTMLButtonElement
      >,
      "ref" | "href"
    > {}

interface FullButtonProps
  extends BaseButtonProps,
    Omit<
      React.DetailedHTMLProps<
        React.ButtonHTMLAttributes<HTMLButtonElement>,
        HTMLButtonElement
      >,
      "href"
    > {
  forwardRef: React.Ref<HTMLButtonElement>;
}

const Button = ({
  mode = "default",
  className = "",
  small,
  large,
  block,
  plain,
  type = "button",
  forwardRef,
  children,
  ...rest
}: FullButtonProps) => (
  <button
    type={type}
    ref={forwardRef}
    className={`${plain ? "" : `btn btn-${mode}`} ${small ? "btn-sm" : ""} ${
      large ? "btn-lg" : ""
    } ${block ? "btn-block" : ""} ${className}`}
    {...rest}
  >
    {children}
  </button>
);

export default React.forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => <Button forwardRef={ref} {...props} />
);
